import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { useNavigate } from 'react-router-dom'
import ProductIcon from '../../assets/images/product_icon.svg'
import OrderListIcon from '../../assets/images/orderList_icon.svg'
import ProductDataUploadIcon from '../../assets/images/productData_upload.svg'
import env from '../../enviroinment'
function AdminNav() {
  let navigate = useNavigate()
  let params = window.location.pathname
  return (
    <div className="AdminMenu">
      <div className="container tableResponsive">
        <ul>
          <li>
            <Nav.Link
              onClick={() => navigate('/dashboard')}
              className={params === '/dashboard' ? 'active' : null}
            >
              <img className="mr_12" src={ProductIcon} />
              <span> Products </span>
            </Nav.Link>
          </li>

          <li>
            <Nav.Link
              onClick={() => navigate('/order')}
              className={params === '/order' ? 'active' : null}
            >
              <img className="mr_12" src={OrderListIcon} />
              <span> Orders</span>
            </Nav.Link>
          </li>
          <li>
            <Nav.Link
              onClick={() => navigate('/product-upload')}
              className={params === '/product-upload' ? 'active' : null}
            >
              <img className="mr_12" src={ProductDataUploadIcon} />
              <span> Product Upload</span>
            </Nav.Link>
          </li>

          <li>
            <Nav.Link
              onClick={() => navigate('/category')}
              className={params === '/category' ? 'active' : null}
            >
              <img className="mr_12" src={ProductDataUploadIcon} />
              <span> Category Settings</span>
            </Nav.Link>
          </li>

          <li>
            <Nav.Link
              onClick={() => window.open(`${env.brandurl}`, '_blank')}
              className="brand-manager"
            >
              {/* <img className="mr_12" /> */}
              <span> Brand Manager</span>
            </Nav.Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AdminNav
