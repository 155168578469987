import React, { useState, useContext, useEffect } from 'react';

import '../../App.css';
import { CartContext } from "../../App";
import MyIframe from './iframe';
import { openKrtiPayment } from '../../services/krti.service';
import { useNavigate, useLocation } from 'react-router-dom';

function KrtiPay() {
    const [total, setTotal] = useState(0);
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();


    const context = useContext(CartContext);
    const checkCart = (arr) => {
        let nav = arr.length <= 0 ? navigate("/user-menu") : null;
        return nav;
    };

    useEffect(() => {
        checkCart(context.cart);
        let total = 0;
        context.cart.map((item) => {
            total += item.selected_quantity_price + item.deliveryFee;
        });
        setTotal(total);

        sessionStorage.setItem('cartTotal', total)

    }, [context.cart]);



    useEffect(() => { setMobile(sessionStorage.getItem('mobileNum') || "") }, [])

    const merchant_secret = process.env.REACT_APP_MERCHANT_SECRET;
    const client_id = process.env.REACT_APP_CLIENT_ID;

    const location = useLocation();
    const url = location.state?.url ? location.state?.url + `&client_id=${client_id}&merchant_secret=${merchant_secret}` : null;







    return (
        <div className="App">

            <MyIframe url={url} />
        </div>
    );
}

export default KrtiPay;


