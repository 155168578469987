import axios from 'axios';
import { useEffect, useState } from 'react';
import env from '../../../enviroinment';
import BackarrowIcon from "../../../assets/images/backarrow_icon.svg";
import RupeeIcon from "../../../assets/images/bx_rupee_icon.svg";
import { useNavigate } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
import LoadingSpinner from '../../loadingSpinner';


const ListAllOrders = () => {
  const [loading,setLoading]=useState(false);
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState(2);
  const customerId = sessionStorage.getItem("CustomerId");
  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("token");
  const business = sessionStorage.getItem("businessName");
  let currencySymbol = sessionStorage.getItem('currencySymbol')

  const navigate = useNavigate();

  const redirectToHome = (redirectRoute) => {
    if (redirectRoute == "home")
      navigate(`/user-menu/${business}`)
  };

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${env.apiurl}/orders/myorders/${customerId}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      .then(({ data: { orders } }) => {
        setLoading(false);
        GetTime(orders);
      })
      .catch((error) =>{
         console.log(error);
         setLoading(false);
        });
  }, []);

  const GetTime = (orders) => {
    const currentTime = new Date().getTime();

    const newOrders = orders.map((order) => {
      const orderTime = new Date(order.createdAt).getTime();
      const differenceInTime = orderTime - currentTime;
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (!(differenceInDays <= 0 && differenceInDays >= -1)) {
        order.createdAt = Math.abs(Math.round(differenceInDays)) + " Days ago";
        return order;
      }

      if (
        !(
          differenceInTime / (1000 * 3600) <= 0 &&
          differenceInTime / (1000 * 3600) >= -1
        )
      ) {
        order.createdAt =
          Math.abs(Math.round(differenceInTime / (1000 * 3600))) + " Hours ago";
        return order;
      }

      if (
        !(
          differenceInTime / (1000 * 60) <= 0 &&
          differenceInTime / (1000 * 60) >= -1
        )
      ) {
        order.createdAt =
          Math.abs(Math.round(differenceInTime / (1000 * 60))) + " Minutes ago";
        return order;
      }

      if (!(differenceInTime / 1000 >= 0 && differenceInTime / 1000 <= -1)) {
        order.createdAt =
          Math.abs(Math.round(differenceInTime / 1000)) + " Seconds ago";
        return order;
      }

      order.createdAt = "Just Now";
      return order;
    });

    setOrders(newOrders);
  };

  // fixed header start
  const [fix, setFix] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  return (
    <>
    {loading ? <LoadingSpinner /> : null}
    <div className="width_500 marginleftRight_auto">
      <div className={fix ? "sticky_top" : "mobiletopHeader mb_16"}>
        <div className="container">
          <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
            <div>
              <a onClick={() => navigate(`/user-menu/${business}`)} style={{ cursor: "pointer" }}>
                <img src={BackarrowIcon} />
              </a>
            </div>
            <div>
              <h5 className="blackgraytext textAlign_center">
                List All Orders
              </h5>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div style={{ paddingBottom: '50px' }} className="container">
        <h5 className="heading20_bold mb_16">Order Details</h5>
        {orders.map((data, index) => (
          <div key={index} className="listOrderItem mb_16">
            <div>
              <p className="dateCreatedAt mb_8">{data.createdAt}</p>

              {data.orderItems
                .filter((items, index) => index < orderItems)
                .map((items, index) => (
                  <div key={index} className="orderGroup">
                    <h5 className="mb_8 orderDetailtext alloyColor">
                      Order Name :<span> {items.name} </span>
                    </h5>
                    <h5 className="mb_8 orderDetailtext alloyColor">
                      No of Items
                      <span>{items.selected_quantity}</span>
                    </h5>

                    <h5 className="orderDetailtext alloyColor">
                      Quantity Price :
                      <span>
                        <div className="displayFlex alignItem_Center">
                          <div className="displayFlex alignItem_Center">
                            {/* <img src={RupeeIcon} className="width_16" /> */}
                          </div>
                          <div>{currencySymbol} {items.selected_quantity_price} </div>
                        </div>
                      </span>
                    </h5>
                  </div>
                ))}

              {data.orderItems.length > 2 && orderItems <= 2 && (
                <button
                  onClick={() => setOrderItems(data.orderItems.length)}
                  className="primarySmallBtn orderButtonCenter"
                >
                  More
                </button>
              )}

              {data.orderItems.length >= 2 && orderItems > 2 && (
                <button
                  onClick={() => setOrderItems(2)}
                  className="smallHoverButton orderButtonCenter"
                >
                  Less
                </button>
              )}
            </div>
            <div className="orderMainstatus">
              <h5 className="mb_8 orderDetailtext blackColor">
                Amount :
                <span>
                  <div className="displayFlex alignItem_Center">
                    <div className="displayFlex alignItem_Center">
                      {/* <img src={RupeeIcon} className="width_16" /> */}
                    </div>
                    <div>{currencySymbol}{data.orderAmount} </div>
                  </div>
                </span>
              </h5>

              <h5 className=" mb_8 orderDetailtext blackColor">
                Order Status:
                <span className="delivereTextClr"> {data.orderStatus} </span>
              </h5>
              <h5 className="orderDetailtext blackColor">
                Payment Status: <span> {data.paymentStatus} </span>
              </h5>

              {data.orderStatus !== 'Delivered' ? <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', justifyContent: 'space-around' }}>
                <button onClick={() => {
                  sessionStorage.setItem('orderId', data._id)
                  navigate(`/tracking`)
                }} style={{ position: 'initial', width: "initial" }} className='AddButton'>Track</button>
              </div> : null}
            </div>
          </div>
        ))}
      </div>
      <MobileMenu redirectToHome={redirectToHome} />
    </div >
    </>
  );
};

export default ListAllOrders;
