const env = {
  apiurl: 'http://localhost:7200', //QA
  brandurl: 'https://sayshop-admin.theecentral.com',
  SecretKey: 'SF@Kr&Ar:):)%1610',
  brand_id: 'd9467e37-dcf1-4ffa-9e42-1382fa0c68b8',
  client_id: `G4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`,
  merchant_secret: 'EsuW6l@DiR!vqh$8',
  KRTI_WEB_API: 'http://localhost:7100',
  KRTI_MOBILE_API: 'http://localhost:7000'

}

// dev env

// const env = {
//   apiurl: 'https://sayshop-dev-api.theecentral.com', //QA
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
//   brand_id: 'd9467e37-dcf1-4ffa-9e42-1382fa0c68b8',
//   client_id: `G4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`,
//   merchant_secret: 'EsuW6l@DiR!vqh$8',
//   KRTI_WEB_API: 'http://52.24.244.211:7005',
//   KRTI_MOBILE_API: 'http://52.24.244.211:7004'
// }

// qa

// const env = {
//   apiurl: 'https://sayshop-qa-api.theecentral.com', //QA
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
// }

// sandbox environment
// const env = {
//   apiurl: 'https://sayshop-sandbox-api.theecentral.com', //sandbox
//   brandurl: 'https://demo-plus.sayshop.co',
//   SecretKey: 'SF@Kr&Ar:):)%1610',
// }



export default env
