import React, { useEffect, useState, useContext } from "react";
import QuantityplusIcon from "../../assets/images/quantityPlus_icon.svg";
import QuantityMinusIcon from "../../assets/images/quantityMinus_icon.svg";
import RupeeIcon from "../../assets/images/bx_rupee_icon.svg";
// import FluentDeleteIcon from "../../assets/images/fluentDelete_icon.svg";
import BackarrowIcon from "../../assets/images/backarrow_icon.svg";
import vegIcon from "../../assets/images/veg_icon.svg";
import DineIcon from "../../assets/images/dine_icon.svg";
import DeliveryIcon from "../../assets/images/delivery_icon.svg";
import { CartContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import env from "../../enviroinment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DialCodes } from "../common/DialCodes";
import LoadingSpinner from "../loadingSpinner";

const Checkout = () => {
  const userId = sessionStorage.getItem("userId");
  let customerId = sessionStorage.getItem("CustomerId");
  const orderId = sessionStorage.getItem("orderId");
  const context = useContext(CartContext);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [deliveryFee, setdeliveryFee] = useState(0);
  const [change, setChange] = useState(false);
  const [mobileModal, setMobileModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [isdelivery, setIsdelivery] = useState(false);
  const [location, setLocation] = useState("");
  const [delivery, setDelivery] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const business = sessionStorage.getItem("businessName");
  const histlocation = useLocation();
  const [loading, setLoading] = useState(false);
  let [selQuantity, setSelQuantity] = useState(false)
  useEffect(() => {
    if (!orderId) {
      checkCart(context.cart);
    }

    let total = 0;
    let totalDeliveryFee = 0;
    console.log("context.cart", context.cart);
    context.cart.map((item) => {
      if (item.selected_quantity > 0) {
        total += item.selected_quantity_price + (item.deliveryFee);
        totalDeliveryFee += item.deliveryFee;
      }
    });
    setTotal(total);
    setdeliveryFee(totalDeliveryFee);
    //console.log("navigate",histlocation.state?.from?.pathname);
    if (histlocation.state?.from?.pathname === "/payment") {
      let orderId = sessionStorage.getItem("orderId");
      orderDetails(orderId);
      setDelivery(true);
    }
  }, [context.cart]);

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      axios
        .get(`${env.apiurl}/orders/${orderId}`, {
          headers: { adminid: userId },
        })
        .then((res) => {
          setLoading(false);
          console.log(res.data.order.orderItems);
          console.log(context);
          context.setCart(res.data.order.orderItems);
        }).catch((error) => {
          setLoading(false);
        });
      setDelivery(true);
    }
  }, []);

  const orderDetails = async (orderId) => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/orders/${orderId}`, {
        headers: { adminid: userId },
      });
      setLoading(false);
      if (res.data.order.deliveryOption === "DineIn") {
        setIsdelivery(false);
      } else {
        setIsdelivery(true);
      }
      console.log("res", res);
    } catch (error) {
      setLoading(false);
    }

  };

  const checkCart = (arr) => {
    let nav = arr.length <= 0 ? navigate("/user-menu") : null;
    return nav;
  };

  const handleIncrementandDecrement = (e, product, type, index) => {
    if (orderId) {
      setDelivery(false);
    }
    console.log("index", index);
    e.preventDefault();
    let data = [];
    let amount = 0;
    let delFee = 0;
    let deliveryFee = 0

    product = product[index];

    console.log("tata", product);

    if (type === "increase") {
      product.selected_quantity =
        product.quantity > product.selected_quantity
          ? product.selected_quantity + 1
          : product.selected_quantity;
      product.selected_quantity_price =
        product.price * product.selected_quantity;
      delFee = product.deliveryFee * product.selected_quantity;
    } else if (type === "decrease") {
      product.selected_quantity =
        product.selected_quantity <= 0
          ? product.selected_quantity
          : product.selected_quantity - 1;

      product.selected_quantity_price =
        product.price * product.selected_quantity;
      delFee = product.deliveryFee * product.selected_quantity;

    }

    // To update selected count and amount
    context.cart.map((item) => {
      let food = item._id === product._id ? product : item;
      amount += item.selected_quantity_price + item.deliveryFee;
      deliveryFee += item.deliveryFee;

      data.push(food);
    });
    console.log("delivery fee", delFee);
    setTotal(amount);
    setdeliveryFee(deliveryFee);
    context.setCart(data);
  };

  const gotoMenu = () => {
    if (delivery) {
      setDelivery(false);
    } else {
      navigate(`/user-menu/${business}`);
    }

  };

  const gotopayment = () => {
    navigate("/payment");
  };

  //Mobile Modal Functionalities
  const gotoMobile = (e, total) => {
    let customerToken = sessionStorage.getItem("customerToken");
    if (orderId) {
      console.log(context.cart);
      let values = {};
      let orderItems = context.cart;
      let orderAmount = total;
      let customerToken = sessionStorage.getItem("customerToken");
      let adminId = sessionStorage.getItem("userId");
      let cusId = sessionStorage.getItem("CustomerId");
      values.orderItems = orderItems;
      values.orderAmount = orderAmount;
      values.customerToken = customerToken;
      values.userId = cusId;
      setLoading(true);
      axios
        .put(`${env.apiurl}/orders/${orderId}`, values, {
          headers: {
            Authorization: `Bearer ${customerToken}`,
            adminid: adminId,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            toast.success("One Step away from Ordering");
            setDelivery(true);
            setChange(false);
            setDelivery(true);
          } else if (res.data.message === "Token Expired") {
            toast.error(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        }).catch((error) => {
          setLoading(false);
        });
      return;
    } else if (!orderId && customerToken) {
      let adminId = sessionStorage.getItem("userId");
      let values = {};
      values.orderItems = context.cart;
      values.orderAmount = total;
      values.customerToken = sessionStorage.getItem("customerToken");
      values.userId = sessionStorage.getItem("CustomerId");
      setLoading(true);
      axios
        .post(`${env.apiurl}/orders`, values, {
          headers: {
            Authorization: `Bearer ${values.customerToken}`,
            adminid: adminId,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            toast.success("One Step away from Ordering");
            sessionStorage.setItem("orderId", res.data.food._id);
            setDelivery(true);
            setChange(false);
          } else if (res.data.message === "Token Expired") {
            toast.error(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        }).catch((error) => {
          setLoading(false);
        });
      return;
    }
    setChange(true);
    setMobileModal(!mobileModal);
  };

  // fixed header start
  const [fix, setFix] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const mobileForm = useFormik({
    initialValues: {
      mobile_code: "",
      mobileNumber: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required("Mobile Number is Required")
        .matches(/^\d{9,10}$/, "Mobile Number is Not Valid"),
      mobile_code: Yup.string().required("Mobile code  is Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      let mobile = "";
      mobile = `${values.mobile_code}${values.mobileNumber}`;
      sessionStorage.setItem("mobileNum", mobile);
      sessionStorage.setItem("phone", values.mobileNumber);
      setLoading(true);
      await axios
        .post(
          `${env.apiurl}/users/login`,
          { mobile: mobile },
          {
            headers: { adminid: userId },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            setMobileModal(false);
            setOtpModal(true);
            toast.success(res.data.message);
            sessionStorage.setItem("CustomerId", res.data.customerId);
            if (res.data.isEmailExists) {
              setIsEmailExists(true);
              otpForm.setFieldValue("isEmailExists", true);
            }
          }
        }).catch((error) => {
          setLoading(false);
        });
    },
  });

  const otpForm = useFormik({
    initialValues: {
      name: "",
      contactMailAddress: '',
      otp: '',
      isEmailExists: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().when("isEmailExists", {
        is: false,
        then: Yup.string()
          .required("name is Required")
          .max(40, 'MAx length exists')
      }),
      isEmailExists: Yup.boolean(),
      contactMailAddress: Yup.string().when("isEmailExists", {
        is: false,
        then: Yup.string()
          .required("Email is Required")
          .email("Invalid Email Address")
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, ('Invalid Email Address')
          ),
      }),
      otp: Yup.string()
        .required("OTP is Required")
        .matches(/^\d{6}$/, "Invalid OTP"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const body = {
        otp: values.otp,
        mailId: values.contactMailAddress,
        userId: customerId,
        name: values.name
      };
      console.log("body", body)
      setLoading(true);
      await axios
        .post(`${env.apiurl}/users/verifyotp`, body, {
          headers: { adminid: userId },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            let mobile = sessionStorage.getItem("mobileNum");
            let comId = sessionStorage.getItem("companyId");
            let parId = sessionStorage.getItem("parentId");

            if (values.contactMailAddress) {
              setLoading(true);
              axios
                .post(`https://email-api.sayshop.co/api/Contact/addContact`, {
                  id: 0,
                  email: values.contactMailAddress,
                  company_id: parseInt(comId),
                  parent_id: parseInt(parId),
                  first_name: values.contactMailAddress.split("@")[0],
                  last_name: values.contactMailAddress.split("@")[0],
                  mailing_address: "YYYYY",
                  phone_number: mobile.toString(),
                  group_list: "",
                })
                .then((res) => {
                  setLoading(false);
                  console.log(res);
                }).catch((error) => {
                  setLoading(false);
                });
            }
            sessionStorage.setItem("customerToken", res.data.token);
            setOtpModal(false);
            toast.success();

            let adminId = sessionStorage.getItem("userId");
            values = {};
            values.orderItems = context.cart;

            values.orderAmount = total;
            values.customerToken = sessionStorage.getItem("customerToken");
            values.userId = sessionStorage.getItem("CustomerId");
            setLoading(true);
            axios
              .post(`${env.apiurl}/orders`, values, {
                headers: {
                  Authorization: `Bearer ${values.customerToken}`,
                  adminid: adminId,
                },
              })
              .then((res) => {
                setLoading(false);
                if (res.data.statusCode === 200) {
                  toast.success("One Step away from Ordering");
                  sessionStorage.setItem("orderId", res.data.food._id);

                  setDelivery(true);
                  setChange(false);

                } else if (res.data.message === "Token Expired") {


                  toast.error(res.data.message);
                } else {
                  toast.error(res.data.message);
                }
              }).catch((error) => {
                setLoading(false);
              });
          } else if (res.data.statusCode === 400 && res.data.validOTP) {
            setMobileModal(true);
            setOtpModal(false);
            toast.error("Your OTP is expired, generate a new OTP");

          } else if (res.data.statusCode === 400 && !res.data.validOTP) {
            toast.error("Please Enter a Valid OTP");


          }
        }).catch((error) => {
          setLoading(false);
        });
    },
  });

  const postLocation = () => {
    setChange(true);
    setDeliveryModal(true);
    setIsdelivery(true);
  };

  const handleSubmitLoc = async (e) => {
    try {
      e.preventDefault();
      let order = sessionStorage.getItem("orderId");
      let body = { deliveryLocation: location };
      setLoading(true);
      let res = await axios.put(
        `${env.apiurl}/users/update-location/${order}`,
        body,
        {
          headers: { adminid: userId },
        }
      );
      setLoading(false);
      if (res.data.statusCode === 200) {
        setChange(false);
        setDeliveryModal(false);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }

  };

  const handleChangeLoc = (e) => {
    let deliveryLocation = e.target.value;
    setLocation(deliveryLocation);
  };

  const setdineIn = () => {
    setIsdelivery(false);
  };
  let currencySymbol = sessionStorage.getItem("currencySymbol");
  console.log(context.cart)
  //disable placeorder if 0 quantity
  useEffect(() => {
    let guess = (context.cart.filter((e) => e.selected_quantity > 0))
    if (guess.length === 0) {
      setSelQuantity(true)
    } else {
      setSelQuantity(false)
    }

  }, [context.cart])
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <section className="bgFitUser">
        {/* <UserHeader /> */}
        <div className="DesktopFrame">
          <div className={fix ? "sticky_top" : "mobiletopHeader"}>
            <div className="container">
              <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
                <div>
                  <a onClick={gotoMenu} style={{ cursor: "pointer" }}>
                    <img src={BackarrowIcon} />
                  </a>
                </div>
                <div>
                  <h5 className="blackgraytext textAlign_center">View Cart</h5>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="container p0">
            {context.cart.map((item, index) => (
              <div key={index}>
                {/* <div className="SelProd_img">
                    <img src={item.image} />
                  </div> */}
                <div className="viewProductDetail mobSpace_mb16">
                  <div className="Viewproduct_content">
                    <div className="displayFlex alignItem_Center mb_6">
                      <div className="mr_10 displayFlex alignItem_Center">
                        <img src={vegIcon} />
                      </div>
                      <div>
                        <h3>{item.name}</h3>
                      </div>
                    </div>

                    <p>{item.description}</p>
                  </div>
                  <div className="Viewproduct_value">
                    <div className="quantityButton mb_20">
                      <button
                        onClick={(e) =>
                          handleIncrementandDecrement(
                            e,
                            context.cart,
                            "decrease",
                            index
                          )
                        }
                      >
                        <img src={QuantityMinusIcon} />
                      </button>
                      <span>{item.selected_quantity}</span>
                      <button
                        onClick={(e) =>
                          handleIncrementandDecrement(
                            e,
                            context.cart,
                            "increase",
                            index
                          )
                        }
                      >
                        <img src={QuantityplusIcon} />
                      </button>
                    </div>
                    <div className="SelectedProd_price">
                      {/* <img src={RupeeIcon} /> */}
                      <span>
                        {currencySymbol} {item.price * item.selected_quantity}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dashedBorderBottom"></div>
                {context.cart.length - 1 === index ? (
                  <div className="YourOrder">
                    <table>
                      <tr>
                        <th>Packing charge</th>
                        <td>{currencySymbol} 0</td>
                      </tr>
                      <tr>
                        <th>Discount</th>
                        <td>{currencySymbol} 0</td>
                      </tr>
                      <tr>
                        <th>Delivery Charge</th>
                        <td className="displayFlex alignItem_Center justifyContent_Center">
                          {/* <img className="width_16" src={RupeeIcon} /> */}
                          <span>
                            {currencySymbol} {deliveryFee}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Grand Total</th>
                        <td className="displayFlex alignItem_Center justifyContent_Center">
                          {/* <img src={RupeeIcon} /> */}
                          <span>
                            {currencySymbol} {total}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  /* <div className="displayFlex alignItem_Center">
                  <p>Delivery Charge</p>
                  <img src={RupeeIcon} />
                  <span className="hightLightThemeClr">0</span>
                </div>
                <div className="displayFlex alignItem_Center">
                  <p>Grand Total</p>
                  <img src={RupeeIcon} />
                  <span className="hightLightThemeClr">{total}</span>
                </div> */
                  /* </div> */
                  <div className="col-md-4"></div>
                )}
              </div>
            ))}
          </div>

          {context.cart && !delivery ? (
            <div className="mobilePt_50">
              <button
                disabled={selQuantity}
                className="fixedGreenButton marginleftRight_auto displayBlock DesktopborderRadius0_mobBorderRadius20 Mobile_bottom_0"
                onClick={(e) => gotoMobile(e, total)}
              >
                Place Order
              </button>
            </div>
          ) : null}
          {delivery && (
            <div className="container ">
              <ul className="Nav_tabs">
                <li>
                  <button
                    className={!isdelivery ? "active" : null}
                    onClick={setdineIn}
                  >
                    <img src={DineIcon} /> Dine In
                  </button>
                </li>
                <li>
                  <button
                    className={isdelivery ? "active" : null}
                    onClick={postLocation}
                  >
                    <img src={DeliveryIcon} /> Delivery
                  </button>
                </li>
              </ul>

              <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
                <div>
                  <button onClick={gotoMenu} className="viewMenu_btn">
                    View Menu
                  </button>
                </div>
                <div>
                  <button onClick={gotopayment} className="smallGreenButton">
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <ToastContainer autoClose={1800} />
        <div className={`Modal ${mobileModal ? "Show" : ""}`}>
          <div className="height_100per">
            <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
              <div className="CenterScreen">
                <h2 className="FrameHeading_28">
                  Enter Your
                  <span className="hightLightThemeClr"> Mobile Number</span>
                </h2>
                <p className="subDescription_text mb_12">
                  Please enter your Number to Receive Otp
                </p>
                <form onSubmit={mobileForm.handleSubmit}>
                  <div className="mb_24">
                    <label className="mb_8 inputTitle">Phone number</label>

                    <div className="displayFlex alignItem_Center gap_16">
                      <div>
                        <select
                          className="MainInput"
                          name="mobile_code"
                          onChange={mobileForm.handleChange}
                          onBlur={mobileForm.handleBlur}
                          value={mobileForm.values.mobile_code}
                        >
                          <option value="">Select Code </option>
                          {DialCodes?.map((code, i) => {
                            return (
                              code.active_flag === "Y" && (
                                <option key={i} value={code.dial_code}>
                                  {`${code.dial_code} (${code.code})`}
                                </option>
                              )
                            );
                          })}
                        </select>

                        {mobileForm.errors.mobile_code &&
                          mobileForm.touched.mobile_code ? (
                          <div className="error_text">
                            {mobileForm.errors.mobile_code}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="width100per">
                        <input
                          type="text"
                          placeholder="Enter phone Number"
                          className="MainInput width100per"
                          name="mobileNumber"
                          onChange={mobileForm.handleChange}
                          onBlur={mobileForm.handleBlur}
                          value={mobileForm.values.mobileNumber?.replace(
                            /[^\d]/g,
                            ""
                          )}
                          maxLength={10}
                        />
                        {mobileForm.errors.mobileNumber &&
                          mobileForm.touched.mobileNumber ? (
                          <div className="error_text">
                            {mobileForm.errors.mobileNumber}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="GreenButton" type="submit">
                    Send Otp
                  </button>
                </form>
              </div>
            </div>
            <ToastContainer autoClose={1800} />
          </div>
        </div>

        <div className={`Modal ${otpModal ? "Show" : ""}`}>
          <div className="height_100per">
            <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
              <div className="CenterScreen">
                <h2 className="FrameHeading_28">
                  Enter <span className="hightLightThemeClr">OTP</span>
                </h2>
                <p className="subDescription_text mb_12">
                  Submit the Otp Received to your Mobile
                </p>

                <form onSubmit={otpForm.handleSubmit}>
                  <div
                    style={{ display: isEmailExists ? 'none' : 'block' }}
                    className="mb_16"
                  >

                    <label className="mb_8 inputTitle">
                      Enter Your Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      className="MainInput"
                      name="name"
                      onChange={otpForm.handleChange}
                      onBlur={otpForm.handleBlur}
                    />
                    {otpForm.errors.name &&
                      otpForm.touched.name ? (
                      <div className="error_text">
                        {otpForm.errors.name}
                      </div>
                    ) : (
                      ''
                    )}


                    {/* email input */}
                    <label className="mb_8 inputTitle">
                      Enter Your Email Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Email Address"
                      className="MainInput"
                      name="contactMailAddress"
                      onChange={otpForm.handleChange}
                      onBlur={otpForm.handleBlur}
                    />
                    {otpForm.errors.contactMailAddress &&
                      otpForm.touched.contactMailAddress ? (
                      <div className="error_text">
                        {otpForm.errors.contactMailAddress}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="mb_24">
                    <label className="mb_8 inputTitle">OTP code here</label>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      className="MainInput"
                      name="otp"
                      onChange={otpForm.handleChange}
                      onBlur={otpForm.handleBlur}
                    />
                    {otpForm.errors.otp && otpForm.touched.otp ? (
                      <div className="error_text">{otpForm.errors.otp}</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button className="GreenButton" type="submit">
                    Verify
                  </button>
                </form>
              </div>
            </div>
            <ToastContainer autoClose={1800} />
          </div>
        </div>

        <div className={`Modal ${deliveryModal ? "Show" : ""}`}>
          <div className="height_100per">
            <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
              <div className="CenterScreen">
                <h2 className="FrameHeading_28">
                  Delivery <span className="hightLightThemeClr">Address</span>
                </h2>
                <form onSubmit={(e) => handleSubmitLoc(e)}>
                  <div className="mb_30">
                    <label className="mb_8 inputTitle">Delivery Location</label>
                    <input
                      type="text"
                      placeholder="Enter delivery location"
                      className="MainInput"
                      name="deliveryLocation"
                      onChange={(e) => handleChangeLoc(e)}
                    />
                  </div>
                  <button className="GreenButton" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <ToastContainer autoClose={1800} />
          </div>
        </div>

        <div className={`Overlay ${change ? "Show" : ""}`} />
      </section>
    </>
  );
};

export default Checkout;
