import React, { useState, useEffect, useContext } from "react";
import "../style.scss";


export default function LoadingSpinner() {
  return (
    <>
      {/* <div class="loaderContainer">
        <div class="circle circle-red"></div>
        <div class="circle circle-blue"></div>
        <div class="circle circle-green"></div>
      </div> */}
      <div className="loaderContainer">
       <img src={require('../assets/images/sayshoploader.svg').default} alt='mySvgImage' />
       </div>
    </>
  );
}
