import React from 'react'
import MainHome from '../../assets/images/home_icon.svg'
import MenuTrackingIcon from '../../assets/images/tracking_menu_icon.svg'
import MenuUserIcon from '../../assets/images/menu_usericon.svg'
import FavouriteIcon from '../../assets/images/favourite_icon.svg'
import { useNavigate } from 'react-router-dom'
const MobileMenu = ({ redirectToHome }) => {

  const navigate = useNavigate();

  return (
    <div className="mobileFooter">
      <ul>
        <li>
          <a className="active">
            <img src={MainHome} onClick={() => redirectToHome("home")} />
          </a>
        </li>
        <li>
          <a>
            <img src={MenuTrackingIcon} onClick={() => navigate('/tracking/all-orders')} />
          </a>
        </li>
        <li>
          <a>
            <img src={FavouriteIcon} />
          </a>
        </li>
        <li>
          <a>
            <img src={MenuUserIcon} onClick={() => navigate('/user-profile')} />
          </a>
        </li>
      </ul>
    </div>
  )
}
export default MobileMenu
