import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { CartContext } from '../../../../App';
import VerifyTick from "../../../../assets/images/verify_tick.png";
import env from '../../../../enviroinment';
import LoadingSpinner from '../../../loadingSpinner';

export const Success = () => {
  const [loading,setLoading]=useState(false);
  const navigate = useNavigate()
  const context = useContext(CartContext)
  const { id } = useParams();
  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("customerToken");

  const paymentSuccess = async () => {
    setLoading(true);
    await axios.put(`${env.apiurl}/payments/paymentstatus/${id}`, '', {
      headers: { Authorization: `Bearer ${token}`, adminid: userId },
    }).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 200) {
        context.setCart([])
        setTimeout(() => {
          navigate('/tracking')
        }, 2500)
      }
    }).catch((error)=>{
      setLoading(false);
    })
  }

  useEffect(() => {
    paymentSuccess()
  }, [])

  return (
    <>
    {loading ? <LoadingSpinner /> : null}
    <div className="height_100per">
      <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
        <div className="WhiteFrame CenterScreen textAlign_center borderRadius_8  height_360 displayFlex alignItem_Center justifyContent_Center flexDirectionColumn">
          <img className="mb_24 width_120" src={VerifyTick} />
          <h2 className="heading28px_bold textAlign_center">
            Payment is successfully processed!
          </h2>
          <p className="subDescription_text textAlign_center">
            Your order has been placed successfully
          </p>
        </div>
      </div>
    </div>
    </>
  );
}