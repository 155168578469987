import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MyIframe = ({ url }) => {

    const navigate = useNavigate();
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // Function to handle messages received from the iframe
    const handleMessage = (event) => {
        // Check if the message is from the iframe
        // debugger
        // if (event.origin !== url) return;

        // Check the content of the message
        if (event.data === 'closeWidget') {
            // Close the widget or perform any other action
            closeWidget();
        }
    };

    // Function to close the widget
    const closeWidget = () => {
        // Close or hide the widget
        console.log("closed")
        navigate('/user-menu/theecode')
    };
    return (
        <iframe
            title="My Iframe"
            src={url}
            width="600"
            height="400"
            frameBorder="0"
        // allowFullScreen
        />
    );
};

export default MyIframe;
