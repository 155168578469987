import React from "react";
import AddTocartIcon from "../../assets/images/addTocart_icon.svg";
import Mainlogo from "../../assets/images/logo.svg";
import SearchIcon from "../../assets/images/search_icon.svg";
import { useState } from "react";
import Food from "./Food";

const UserHeader = ({ selectedItems, onChange }) => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useState("light");
  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      setTheme('dark')
    }
    else {
      document.documentElement.setAttribute('data-theme', 'light');
      setTheme('light')
    }

  };
  return (
    <div>
      <header>
        <div className="container">
          <div className="userTopHeader">
            <div className="adminLogo">
              <img src={Mainlogo} />
            </div>
            {/* <div className="yourPlace"> */}
            {/* <h5>Welcome, Say Food!</h5> */}
            {/* <p>Demo Text Demo Text Demo Text</p> */}
            {/* </div> */}
            <div className="displayFlex alignItem_Center">
              <div className="DesktopSearch_on mr_30">
                <div className="SearchForm">
                  <div className="input-group-prepend">
                    <span className="inputGroup_text">
                      <img src={SearchIcon} />
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Search ..."
                    onChange={onChange}
                  />
                </div>
              </div>
              <div>
                <a className="CirclecountBox">
                  <img src={AddTocartIcon} />
                  <span>{selectedItems}</span>
                </a>
              </div>
              {/* Remove the style to add the theme toggle */}
              <div style={{ display: 'none' }} className="theme-switch-wrapper">
                <label className="theme-switch" htmlFor="checkbox">
                  <input type="checkbox" id="checkbox" onClick={(e) => { handleThemeToggle(e) }} />
                  <div className="slider round"></div>
                </label>

              </div>
            </div>
          </div>
        </div>
      </header >
    </div >
  );
};

export default UserHeader;
