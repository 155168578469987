import React, { useEffect } from "react";
import { withRouter } from "./with-router";
import { useNavigate } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
   let navigate = useNavigate()
  let location 
  if(props.router.location.pathname==='/dashboard' || props.router.location.pathname==='/order' ||props.router.location.pathname==='/product-upload'
  ||props.router.location.pathname==='/business-upi'||props.router.location.pathname==='/admin-users'|| props.router.location.pathname==='/change-password' || props.router.location.pathname==='/admin-profile'){
    location = props.router.location;
  }

  useEffect(() => {
    

    if (sessionStorage.getItem('token')) {
      const decodedJwt = parseJwt(sessionStorage.getItem('token'));

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.setloggedIn(false);
        sessionStorage.clear();
        navigate('/login');
      }
    }
  }, [location]);

  return <div></div>;
};

export default withRouter(AuthVerify);