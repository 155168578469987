import React, { useContext, useEffect, useState } from "react";
// import UserHeader from './UserHeader'
import ProgressCheck from "../../assets/images/progressCheck.svg";
import OrderIcon from "../../assets/images/orderIcon.svg";
import CookingIcon from "../../assets/images/cooking_icon.svg";
import OnthewayTravelIcon from "../../assets/images/ontheway_travel.svg";
import DeliveredIcon from "../../assets/images/delivered_icon.svg";
import RupeeIcon from "../../assets/images/bx_rupee_icon.svg";
import BackarrowIcon from "../../assets/images/backarrow_icon.svg";
import { CartContext } from "../../App";

import axios from "axios";
import env from "../../enviroinment";
import { Navigate, useNavigate } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import LoadingSpinner from "../loadingSpinner";
const Tracking = () => {
  // fixed header start
  const [loading, setLoading] = useState(false);
  const [fix, setFix] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [order, setOrder] = useState("");
  let currencySymbol = sessionStorage.getItem('currencySymbol')
  let navigate = useNavigate();
  const business = sessionStorage.getItem("businessName");
  let context = useContext(CartContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let order_id = sessionStorage.getItem("orderId");
  let userId = sessionStorage.getItem("userId");
  const viewProduct = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/orders/${order_id}`, {
        headers: { adminid: userId },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        setOrder(res.data.order.orderStatus);
        setItem(res.data.order.orderItems);
        let arr = [];
        const { contactName, deliveryLocation, orderAmount, _id } = res.data;
        let object = {
          contactName: contactName,
          deliveryLocation: deliveryLocation,
          orderAmount: orderAmount,
          _id: _id,
        };
        arr.push(object);
        setData(arr);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const redirectToHome = (redirectRoute) => {
    context.cart.length = 0;
    if (redirectRoute == "home") navigate(`/user-menu/${business}`);
  };
  useEffect(() => {
    viewProduct();
  }, []);
  return (
    <>
    {loading ? <LoadingSpinner /> : null}
      <section className="bgFitUser">
        {/* <UserHeader /> */}

        <div className="DesktopFrame mobSpace_pb16">
          <div className={fix ? "sticky_top" : "mobiletopHeader"}>
            <div className="container">
              <div>
                <h5 className="blackgraytext textAlign_center">
                  Order Tracking Status
                </h5>
              </div>
            </div>
          </div>
          {/* <div className={fix ? "sticky_top" : "mobiletopHeader mb_16"}>
        <div className="container">
          <ul className="trackingYourOrder">
            <li>
              <a className={order === "Ordered" ? "active" : null}>
                <img src={OrderIcon} />
              </a>
            </li>
            <li>
              <a className={order === "Ordered" ? "active" : null}>
                <img src={CookingIcon} />
              </a>
            </li>
            <li>
              <a className={order === "Out for delivery" ? "active" : null}>
                <img src={OnthewayTravelIcon} />
              </a>
            </li>
            <li>
              <a className={order === "Delivered" ? "active" : null}>
                <img src={DeliveredIcon} />
              </a>
            </li>
          </ul>
        </div>
      </div> */}
        <div style={{ paddingBottom: '50px' }} className="container">
          <div className="next_step_slider">
            <ul>
              <li className={order === "Ordered" ? "active" : null}>
                <a>
                  <img src={ProgressCheck} />
                </a>
                <h3>Ordered</h3>
              </li>
              <li className={order === "Ordered" ? "active" : null}>
                <a>
                  <img src={ProgressCheck} />
                </a>
                <h3>Preparing</h3>
              </li>
              <li className={order === "Out for delivery" ? "active" : null}>
                <a>
                  <img src={ProgressCheck} />
                </a>
                <h3>Out for Delivery</h3>
              </li>
              <li className={order === "Delivered" ? "active" : null}>
                <a>
                  <img src={ProgressCheck} />
                </a>
                <h3>Delivered</h3>
              </li>
            </ul>
          </div>
        </div>
        <div className="container p0">
          <div className="dashedBorderBottom"></div>
          {data &&
            data?.map((user, index) => (
              <div key={index}>
                <div className="trackingorder_detail">
                  <h3>User Details</h3>
                  <p className="mb_4">{user.contactName}</p>
                  <p>{user.deliveryLocation}</p>
                </div>
                <div className="dashedBorderBottom"></div>
                <div className="trackingorder_detail">
                  <h3>Order Details</h3>
                  <p className="mb_8">
                    <span className="Semibold">Order Id :</span> {user._id}
                  </p>
                  <p className="Semibold mb_8">Order Items :</p>
                  <div className="totalorder_list">
                    {item &&
                      item?.map((item, index) => (
                        <span key={index} className="orderItem">{item.name}</span>
                      ))}
                  </div>
                </div>
                <div className="dashedBorderBottom"></div>
                <div className="trackingorder_detail">
                  <div className="displayFlex justifyContent_spaceBetween alignItem_Center">
                    <div>
                      <h5 className="mb_0">Total :</h5>
                    </div>
                    <div>
                      <h5 className="mb_0">
                        {/* <img className="mr_4 width_19" src={RupeeIcon} /> */}
                        {currencySymbol}{user.orderAmount}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="dashedBorderBottom"></div>
              </div>
            ))}
        </div>
      </div>
      <MobileMenu redirectToHome={redirectToHome} />
    </section>
    </>
  );
};
export default Tracking;
