
import React, { useState } from "react";
import VerifyTick from "../../assets/images/verify_tick.png";

;

const OrderMessage = () => {
  return (
    <div className="height_100per">
      <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
        <div className="WhiteFrame CenterScreen textAlign_center">
          <img className="mb_24" src={VerifyTick} style={{ width: "100px" }} />
          <h2 className="heading28px_bold textAlign_center">
            Thank You!
          </h2>
          <p className="subDescription_text textAlign_center">
          Order has been processed!
          </p>
        </div>
      </div>
    </div>
  );
};
export default OrderMessage;
