export const Currency = [
    {
      "name": "Afganistan",
      "active": true,
      "createdAt": "2022-01-17T16:04:28.516912",
      "mobile_code": "93",
      "country_codes": "AFG",
      "currency_codes": "AFN"
    },
    {
      "name": "Eritrea",
      "active": true,
      "createdAt": "2022-01-17T18:33:54.559501",
      "mobile_code": "291",
      "country_codes": "ERI",
      "currency_codes": "ERN"
    },
    {
      "name": "Albania",
      "active": true,
      "createdAt": "2022-01-17T16:12:47.467174",
      "mobile_code": "355",
      "country_codes": "ALB",
      "currency_codes": "Lek"
    },
    {
      "name": "American Samoa",
      "active": true,
      "createdAt": "2022-01-17T16:18:35.298007",
      "mobile_code": "1 684",
      "country_codes": "ASM",
      "currency_codes": "USD"
    },
    {
      "name": "Aland Islands",
      "active": true,
      "createdAt": "2022-01-17T16:09:07.101506",
      "mobile_code": "358 18",
      "country_codes": "ALA",
      "currency_codes": "EUR"
    },
    {
      "name": "AndorrA",
      "active": true,
      "createdAt": "2022-01-17T16:21:39.998765",
      "mobile_code": "376",
      "country_codes": "AND",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Angola",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:24:45.529101",
    //   "mobile_code": "244",
    //   "country_codes": "AGO",
    //   "currency_codes": "AOA"
    // },
    // {
    //   "name": "Anguilla",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:27:24.895461",
    //   "mobile_code": "1 264",
    //   "country_codes": "AIA",
    //   "currency_codes": "XCD"
    // },
    // {
    //   "name": "Antarctica",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:30:07.922765",
    //   "mobile_code": "6721",
    //   "country_codes": "ATA",
    //   "currency_codes": "-"
    // },
    // {
    //   "name": "Antigua and Barbuda",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:31:37.129427",
    //   "mobile_code": "1 268",
    //   "country_codes": "ATG",
    //   "currency_codes": "XCD"
    // },
    {
      "name": "Argentina",
      "active": true,
      "createdAt": "2022-01-17T16:33:09.875996",
      "mobile_code": "54",
      "country_codes": "ARG",
      "currency_codes": "ARS"
    },
    {
      "name": "Armenia",
      "active": true,
      "createdAt": "2022-01-17T16:34:53.451001",
      "mobile_code": "374",
      "country_codes": "ARM",
      "currency_codes": "AMD"
    },
    // {
    //   "name": "Aruba",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:36:59.052269",
    //   "mobile_code": "297",
    //   "country_codes": "ABW",
    //   "currency_codes": "AWG"
    // },
    {
      "name": "Australia",
      "active": true,
      "createdAt": "2022-01-17T16:38:36.170059",
      "mobile_code": "61",
      "country_codes": "AUS",
      "currency_codes": "AUD"
    },
    {
      "name": "Austria",
      "active": true,
      "createdAt": "2022-01-17T16:40:45.595838",
      "mobile_code": "43",
      "country_codes": "AUT",
      "currency_codes": "EUR"
    },
    {
      "name": "Azerbaijan",
      "active": true,
      "createdAt": "2022-01-17T16:42:11.523848",
      "mobile_code": "994",
      "country_codes": "AZE",
      "currency_codes": "AZN"
    },
    // {
    //   "name": "Bahamas",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:45:53.795139",
    //   "mobile_code": "1 242",
    //   "country_codes": "BHS",
    //   "currency_codes": "BSD"
    // },
    {
      "name": "Bangladesh",
      "active": true,
      "createdAt": "2022-01-17T16:48:50.610311",
      "mobile_code": "880",
      "country_codes": "BGD",
      "currency_codes": "BDT"
    },
    // {
    //   "name": "Barbados",
    //   "active": true,
    //   "createdAt": "2022-01-17T16:50:31.989253",
    //   "mobile_code": "1 246",
    //   "country_codes": "BRB",
    //   "currency_codes": "BBD"
    // },
    {
      "name": "Belarus",
      "active": true,
      "createdAt": "2022-01-17T16:56:18.422531",
      "mobile_code": "375",
      "country_codes": "BLR",
      "currency_codes": "BYN"
    },
    {
      "name": "Belgium",
      "active": true,
      "createdAt": "2022-01-17T16:59:00.304384",
      "mobile_code": "32",
      "country_codes": "BEL",
      "currency_codes": "EUR"
    },
    {
      "name": "Belize",
      "active": true,
      "createdAt": "2022-01-17T17:00:47.143295",
      "mobile_code": "501",
      "country_codes": "BLZ",
      "currency_codes": "BZD"
    },
    {
      "name": "Benin",
      "active": true,
      "createdAt": "2022-01-17T17:02:44.214591",
      "mobile_code": "229",
      "country_codes": "BEN",
      "currency_codes": "XOF"
    },
    // {
    //   "name": "Bermuda",
    //   "active": true,
    //   "createdAt": "2022-01-17T17:04:31.841548",
    //   "mobile_code": "1 441",
    //   "country_codes": "BMU",
    //   "currency_codes": "BMD"
    // },
    // {
    //   "name": "Bhutan",
    //   "active": true,
    //   "createdAt": "2022-01-17T17:06:00.443186",
    //   "mobile_code": "975",
    //   "country_codes": "BTN",
    //   "currency_codes": "BTN"
    // },
    {
      "name": "Bolivia",
      "active": true,
      "createdAt": "2022-01-17T17:07:35.605522",
      "mobile_code": "591",
      "country_codes": "BOL",
      "currency_codes": "BOB"
    },
    {
      "name": "Bosnia and Herzegovina",
      "active": true,
      "createdAt": "2022-01-17T17:09:53.52815",
      "mobile_code": "387",
      "country_codes": "BIH",
      "currency_codes": "BAM"
    },
    {
      "name": "Botswana",
      "active": true,
      "createdAt": "2022-01-17T17:11:21.284419",
      "mobile_code": "267",
      "country_codes": "BWA",
      "currency_codes": "BWP"
    },
    {
      "name": "Bouvet Island",
      "active": true,
      "createdAt": "2022-01-17T17:12:48.441756",
      "mobile_code": "47",
      "country_codes": "BVT",
      "currency_codes": "NOK"
    },
    {
      "name": "Brazil",
      "active": true,
      "createdAt": "2022-01-17T17:15:11.796596",
      "mobile_code": "55",
      "country_codes": "BRA",
      "currency_codes": "BRL"
    },
    {
      "name": "British Indian Ocean Territory",
      "active": true,
      "createdAt": "2022-01-17T17:16:39.585915",
      "mobile_code": "246",
      "country_codes": "IOT",
      "currency_codes": "USD"
    },
    {
      "name": "Brunei Darussalam",
      "active": true,
      "createdAt": "2022-01-17T17:17:47.395045",
      "mobile_code": "673",
      "country_codes": "BRN",
      "currency_codes": "BND"
    },
    {
      "name": "Bulgaria",
      "active": true,
      "createdAt": "2022-01-17T17:19:03.416514",
      "mobile_code": "359",
      "country_codes": "BGR",
      "currency_codes": "BGN"
    },
    {
      "name": "Burkina Faso",
      "active": true,
      "createdAt": "2022-01-17T17:20:36.045167",
      "mobile_code": "226",
      "country_codes": "BFA",
      "currency_codes": "XOF"
    },
    {
      "name": "Burundi",
      "active": true,
      "createdAt": "2022-01-17T17:22:41.034801",
      "mobile_code": "257",
      "country_codes": "BDI",
      "currency_codes": "BIF"
    },
    {
      "name": "Cambodia",
      "active": true,
      "createdAt": "2022-01-17T17:37:46.827495",
      "mobile_code": "855",
      "country_codes": "KHM",
      "currency_codes": "KHR"
    },
    {
      "name": "Cameroon",
      "active": true,
      "createdAt": "2022-01-17T17:52:53.081081",
      "mobile_code": "237",
      "country_codes": "CMR",
      "currency_codes": "XAF"
    },
    {
      "name": "Canada",
      "active": true,
      "createdAt": "2022-01-17T17:54:23.957162",
      "mobile_code": "1",
      "country_codes": "CAN",
      "currency_codes": "CAD"
    },
    {
      "name": "Cape Verde",
      "active": true,
      "createdAt": "2022-01-17T17:56:28.920002",
      "mobile_code": "238",
      "country_codes": "CPV",
      "currency_codes": "CVE"
    },
    // {
    //   "name": "Cayman Islands",
    //   "active": true,
    //   "createdAt": "2022-01-17T17:57:53.56012",
    //   "mobile_code": "1 345",
    //   "country_codes": "CYM",
    //   "currency_codes": "KYD"
    // },
    {
      "name": "Central African Republic",
      "active": true,
      "createdAt": "2022-01-17T17:59:22.608159",
      "mobile_code": "236",
      "country_codes": "CAF",
      "currency_codes": "XAF"
    },
    {
      "name": "Chad",
      "active": true,
      "createdAt": "2022-01-17T18:00:32.112152",
      "mobile_code": "235",
      "country_codes": "TCD",
      "currency_codes": "XAF"
    },
    // {
    //   "name": "Chile",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:01:56.883148",
    //   "mobile_code": "56",
    //   "country_codes": "CHL",
    //   "currency_codes": "CLF"
    // },
    {
      "name": "China",
      "active": true,
      "createdAt": "2022-01-17T18:03:13.901203",
      "mobile_code": "86",
      "country_codes": "CHN",
      "currency_codes": "CNY"
    },
    {
      "name": "Christmas Island",
      "active": true,
      "createdAt": "2022-01-17T18:04:39.70295",
      "mobile_code": "61",
      "country_codes": "CXR",
      "currency_codes": "AUD"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "active": true,
      "createdAt": "2022-01-17T18:05:52.723759",
      "mobile_code": "61",
      "country_codes": "CCK",
      "currency_codes": "AUD"
    },
    {
      "name": "Colombia",
      "active": true,
      "createdAt": "2022-01-17T18:07:16.072647",
      "mobile_code": "57",
      "country_codes": "COL",
      "currency_codes": "COP"
    },
    {
      "name": "Comoros",
      "active": true,
      "createdAt": "2022-01-17T18:08:17.30181",
      "mobile_code": "269",
      "country_codes": "COM",
      "currency_codes": "KMF"
    },
    {
      "name": "Congo",
      "active": true,
      "createdAt": "2022-01-17T18:09:34.291313",
      "mobile_code": "243",
      "country_codes": "COG",
      "currency_codes": "XAF"
    },
    {
      "name": "Congo, The Democratic Republic of the",
      "active": true,
      "createdAt": "2022-01-17T18:10:40.842112",
      "mobile_code": "242",
      "country_codes": "COD",
      "currency_codes": "CDF"
    },
    {
      "name": "Cook Islands",
      "active": true,
      "createdAt": "2022-01-17T18:11:50.357593",
      "mobile_code": "682",
      "country_codes": "COK",
      "currency_codes": "NZD"
    },
    {
      "name": "Costa Rica",
      "active": true,
      "createdAt": "2022-01-17T18:14:58.844034",
      "mobile_code": "506",
      "country_codes": "CRI",
      "currency_codes": "CRC"
    },
    {
      "name": "Cote D'Ivoire",
      "active": true,
      "createdAt": "2022-01-17T18:16:03.4978",
      "mobile_code": "225",
      "country_codes": "CIV",
      "currency_codes": "XOF"
    },
    {
      "name": "Croatia",
      "active": true,
      "createdAt": "2022-01-17T18:17:19.217123",
      "mobile_code": "385",
      "country_codes": "HRV",
      "currency_codes": "HRK"
    },
    // {
    //   "name": "Cuba",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:18:49.443276",
    //   "mobile_code": "53",
    //   "country_codes": "CUB",
    //   "currency_codes": "CUP"
    // },
    {
      "name": "Cyprus",
      "active": true,
      "createdAt": "2022-01-17T18:20:52.951501",
      "mobile_code": "357",
      "country_codes": "CYP",
      "currency_codes": "EUR"
    },
    {
      "name": "Czech Republic",
      "active": true,
      "createdAt": "2022-01-17T18:22:43.209444",
      "mobile_code": "420",
      "country_codes": "CZE",
      "currency_codes": "CZK"
    },
    {
      "name": "Denmark",
      "active": true,
      "createdAt": "2022-01-17T18:23:54.079855",
      "mobile_code": "45",
      "country_codes": "DNK",
      "currency_codes": "DKK"
    },
    {
      "name": "Djibouti",
      "active": true,
      "createdAt": "2022-01-17T18:25:02.606039",
      "mobile_code": "253",
      "country_codes": "DJI",
      "currency_codes": "DJF"
    },
    // {
    //   "name": "Dominica",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:26:09.570606",
    //   "mobile_code": "1 767",
    //   "country_codes": "DMA",
    //   "currency_codes": "XCD"
    // },
    {
      "name": "Dominican Republic",
      "active": true,
      "createdAt": "2022-01-17T18:27:32.087825",
      "mobile_code": "1 809",
      "country_codes": "DOM",
      "currency_codes": "DOP"
    },
    {
      "name": "Ecuador",
      "active": true,
      "createdAt": "2022-01-17T18:28:46.921873",
      "mobile_code": "593",
      "country_codes": "ECU",
      "currency_codes": "USD"
    },
    {
      "name": "Egypt",
      "active": true,
      "createdAt": "2022-01-17T18:29:57.049315",
      "mobile_code": "20",
      "country_codes": "EGY",
      "currency_codes": "EGP"
    },
    // {
    //   "name": "El Salvador",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:31:57.986508",
    //   "mobile_code": "503",
    //   "country_codes": "SLV",
    //   "currency_codes": "SVC"
    // },
    {
      "name": "Algeria",
      "active": true,
      "createdAt": "2022-01-17T16:15:22.750826",
      "mobile_code": "213",
      "country_codes": "DZA",
      "currency_codes": "DZD"
    },
    {
      "name": "Bahrain",
      "active": true,
      "createdAt": "2022-01-17T16:47:18.283711",
      "mobile_code": "973",
      "country_codes": "BHR",
      "currency_codes": "BHD"
    },
    {
      "name": "Equatorial Guinea",
      "active": true,
      "createdAt": "2022-01-17T18:32:56.039304",
      "mobile_code": "240",
      "country_codes": "GNQ",
      "currency_codes": "XAF"
    },
    {
      "name": "Estonia",
      "active": true,
      "createdAt": "2022-01-17T18:34:57.138097",
      "mobile_code": "372",
      "country_codes": "EST",
      "currency_codes": "EUR"
    },
    {
      "name": "Ethiopia",
      "active": true,
      "createdAt": "2022-01-17T18:35:54.433086",
      "mobile_code": "251",
      "country_codes": "ETH",
      "currency_codes": "ETB"
    },
    // {
    //   "name": "Falkland Islands (Malvinas)",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:36:51.539191",
    //   "mobile_code": "500",
    //   "country_codes": "FLK",
    //   "currency_codes": "FKP"
    // },
    {
      "name": "Faroe Islands",
      "active": true,
      "createdAt": "2022-01-17T18:38:04.56406",
      "mobile_code": "298",
      "country_codes": "FRO",
      "currency_codes": "DKK"
    },
    // {
    //   "name": "Fiji",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:38:58.977328",
    //   "mobile_code": "679",
    //   "country_codes": "FJI",
    //   "currency_codes": "FJD"
    // },
    {
      "name": "Finland",
      "active": true,
      "createdAt": "2022-01-17T18:39:51.137036",
      "mobile_code": "358",
      "country_codes": "FIN",
      "currency_codes": "EUR"
    },
    {
      "name": "France",
      "active": true,
      "createdAt": "2022-01-17T18:40:45.187926",
      "mobile_code": "33",
      "country_codes": "FRA",
      "currency_codes": "EUR"
    },
    {
      "name": "French Guiana",
      "active": true,
      "createdAt": "2022-01-17T18:41:58.979401",
      "mobile_code": "594",
      "country_codes": "GUF",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "French Polynesia",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:43:10.098844",
    //   "mobile_code": "689",
    //   "country_codes": "PYF",
    //   "currency_codes": "XPF"
    // },
    {
      "name": "French Southern Territories",
      "active": true,
      "createdAt": "2022-01-17T18:44:17.984414",
      "mobile_code": "-",
      "country_codes": "ATF",
      "currency_codes": "EUR"
    },
    {
      "name": "Gabon",
      "active": true,
      "createdAt": "2022-01-17T18:45:21.644232",
      "mobile_code": "241",
      "country_codes": "GAB",
      "currency_codes": "XAF"
    },
    {
      "name": "Georgia",
      "active": true,
      "createdAt": "2022-01-17T18:47:42.10822",
      "mobile_code": "995",
      "country_codes": "GEO",
      "currency_codes": "GEL"
    },
    {
      "name": "Germany",
      "active": true,
      "createdAt": "2022-01-17T18:49:24.690793",
      "mobile_code": "49",
      "country_codes": "DEU",
      "currency_codes": "EUR"
    },
    {
      "name": "Ghana",
      "active": true,
      "createdAt": "2022-01-17T18:51:32.408365",
      "mobile_code": "233",
      "country_codes": "GHA",
      "currency_codes": "GHS"
    },
    // {
    //   "name": "Gibraltar",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:53:19.13055",
    //   "mobile_code": "350",
    //   "country_codes": "GIB",
    //   "currency_codes": "GIP"
    // },
    {
      "name": "Greece",
      "active": true,
      "createdAt": "2022-01-17T18:54:09.762868",
      "mobile_code": "30",
      "country_codes": "GRC",
      "currency_codes": "EUR"
    },
    {
      "name": "Greenland",
      "active": true,
      "createdAt": "2022-01-17T18:55:31.147856",
      "mobile_code": "299",
      "country_codes": "GRL",
      "currency_codes": "DKK"
    },
    // {
    //   "name": "Grenada",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:57:02.561639",
    //   "mobile_code": "1 473",
    //   "country_codes": "GRD",
    //   "currency_codes": "XCD"
    // },
    {
      "name": "Guadeloupe",
      "active": true,
      "createdAt": "2022-01-17T18:58:13.256285",
      "mobile_code": "590",
      "country_codes": "GLP",
      "currency_codes": "EUR"
    },
    {
      "name": "Guam",
      "active": true,
      "createdAt": "2022-01-17T18:59:26.980447",
      "mobile_code": "1 671",
      "country_codes": "GUM",
      "currency_codes": "USD"
    },
    {
      "name": "Guatemala",
      "active": true,
      "createdAt": "2022-01-17T19:00:26.423518",
      "mobile_code": "502",
      "country_codes": "GTM",
      "currency_codes": "GTQ"
    },
    {
      "name": "Guernsey",
      "active": true,
      "createdAt": "2022-01-17T19:01:41.349295",
      "mobile_code": "44",
      "country_codes": "GGY",
      "currency_codes": "GBP"
    },
    {
      "name": "Guinea",
      "active": true,
      "createdAt": "2022-01-17T19:02:40.57667",
      "mobile_code": "224",
      "country_codes": "GIN",
      "currency_codes": "GNF"
    },
    {
      "name": "Guinea-Bissau",
      "active": true,
      "createdAt": "2022-01-17T19:03:32.982775",
      "mobile_code": "245",
      "country_codes": "GNB",
      "currency_codes": "XOF"
    },
    // {
    //   "name": "Guyana",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:04:23.952088",
    //   "mobile_code": "592",
    //   "country_codes": "GUY",
    //   "currency_codes": "GYD"
    // },
    {
      "name": "Haiti",
      "active": true,
      "createdAt": "2022-01-17T19:05:57.623767",
      "mobile_code": "509",
      "country_codes": "HTI",
      "currency_codes": "USD"
    },
    {
      "name": "Heard Island and Mcdonald Islands",
      "active": true,
      "createdAt": "2022-01-17T19:07:11.537256",
      "mobile_code": "1 672",
      "country_codes": "HMD",
      "currency_codes": "AUD"
    },
    {
      "name": "Holy See (Vatican City State)",
      "active": true,
      "createdAt": "2022-01-17T19:08:35.441295",
      "mobile_code": "379",
      "country_codes": "VAT",
      "currency_codes": "EUR"
    },
    {
      "name": "Honduras",
      "active": true,
      "createdAt": "2022-01-17T19:09:37.432158",
      "mobile_code": "504",
      "country_codes": "HND",
      "currency_codes": "HNL"
    },
    {
      "name": "Hong Kong",
      "active": true,
      "createdAt": "2022-01-17T19:10:27.003182",
      "mobile_code": "852",
      "country_codes": "HKG",
      "currency_codes": "HKD"
    },
    {
      "name": "Hungary",
      "active": true,
      "createdAt": "2022-01-17T19:11:14.804951",
      "mobile_code": "36",
      "country_codes": "HUN",
      "currency_codes": "HUF"
    },
    {
      "name": "Iceland",
      "active": true,
      "createdAt": "2022-01-17T19:12:10.095632",
      "mobile_code": "354",
      "country_codes": "ISL",
      "currency_codes": "ISK"
    },
    {
      "name": "Indonesia",
      "active": true,
      "createdAt": "2022-01-17T19:14:05.642362",
      "mobile_code": "62",
      "country_codes": "IDN",
      "currency_codes": "IDR"
    },
    {
      "name": "Iran, Islamic Republic Of",
      "active": true,
      "createdAt": "2022-01-17T19:14:58.938111",
      "mobile_code": "98",
      "country_codes": "IRN",
      "currency_codes": "IRR"
    },
    {
      "name": "Iraq",
      "active": true,
      "createdAt": "2022-01-17T19:16:04.684551",
      "mobile_code": "964",
      "country_codes": "IRQ",
      "currency_codes": "IQD"
    },
    {
      "name": "Ireland",
      "active": true,
      "createdAt": "2022-01-17T19:17:09.66975",
      "mobile_code": "353",
      "country_codes": "IRL",
      "currency_codes": "EUR"
    },
    {
      "name": "Isle of Man",
      "active": true,
      "createdAt": "2022-01-17T19:18:19.555028",
      "mobile_code": "44",
      "country_codes": "IMN",
      "currency_codes": "GBP"
    },
    {
      "name": "Israel",
      "active": true,
      "createdAt": "2022-01-17T19:19:11.36574",
      "mobile_code": "972",
      "country_codes": "ISR",
      "currency_codes": "ILS"
    },
    {
      "name": "Italy",
      "active": true,
      "createdAt": "2022-01-17T19:20:07.019252",
      "mobile_code": "39",
      "country_codes": "ITA",
      "currency_codes": "EUR"
    },
    {
      "name": "Jamaica",
      "active": true,
      "createdAt": "2022-01-17T19:21:25.171283",
      "mobile_code": "1 876",
      "country_codes": "JAM",
      "currency_codes": "JMD"
    },
    {
      "name": "Japan",
      "active": true,
      "createdAt": "2022-01-17T19:22:11.168983",
      "mobile_code": "81",
      "country_codes": "JPN",
      "currency_codes": "JPY"
    },
    {
      "name": "Jersey",
      "active": true,
      "createdAt": "2022-01-17T19:23:00.667548",
      "mobile_code": "44",
      "country_codes": "JEY",
      "currency_codes": "GBP"
    },
    {
      "name": "Jordan",
      "active": true,
      "createdAt": "2022-01-17T19:23:51.863277",
      "mobile_code": "962",
      "country_codes": "JOR",
      "currency_codes": "JOD"
    },
    {
      "name": "Kazakhstan",
      "active": true,
      "createdAt": "2022-01-17T19:24:58.827667",
      "mobile_code": "7",
      "country_codes": "KAZ",
      "currency_codes": "KZT"
    },
    {
      "name": "Kenya",
      "active": true,
      "createdAt": "2022-01-17T19:25:56.55872",
      "mobile_code": "254",
      "country_codes": "KEN",
      "currency_codes": "KES"
    },
    {
      "name": "Kiribati",
      "active": true,
      "createdAt": "2022-01-17T19:26:44.120378",
      "mobile_code": "686",
      "country_codes": "KIR",
      "currency_codes": "AUD"
    },
    // {
    //   "name": "Korea, Democratic People'S Republic of",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:27:55.077259",
    //   "mobile_code": "850",
    //   "country_codes": "PRK",
    //   "currency_codes": "KPW"
    // },
    {
      "name": "Korea, Republic of",
      "active": true,
      "createdAt": "2022-01-17T19:28:57.67608",
      "mobile_code": "82",
      "country_codes": "KOR",
      "currency_codes": "KRW"
    },
    {
      "name": "Kuwait",
      "active": true,
      "createdAt": "2022-01-17T19:29:57.636674",
      "mobile_code": "965",
      "country_codes": "KWT",
      "currency_codes": "KWD"
    },
    // {
    //   "name": "Kyrgyzstan",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:31:05.235399",
    //   "mobile_code": "996",
    //   "country_codes": "KGZ",
    //   "currency_codes": "KGS"
    // },
    // {
    //   "name": "Lao People'S Democratic Republic",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:32:13.20112",
    //   "mobile_code": "856",
    //   "country_codes": "LAO",
    //   "currency_codes": "LAK"
    // },
    {
      "name": "Latvia",
      "active": true,
      "createdAt": "2022-01-17T19:33:40.195234",
      "mobile_code": "371",
      "country_codes": "LVA",
      "currency_codes": "EUR"
    },
    {
      "name": "Lebanon",
      "active": true,
      "createdAt": "2022-01-17T19:35:01.027577",
      "mobile_code": "961",
      "country_codes": "LBN",
      "currency_codes": "LBP"
    },
    // {
    //   "name": "Lesotho",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:36:02.533189",
    //   "mobile_code": "266",
    //   "country_codes": "LSO",
    //   "currency_codes": "LSL"
    // },
    {
      "name": "Liberia",
      "active": true,
      "createdAt": "2022-01-17T19:38:11.132576",
      "mobile_code": "231",
      "country_codes": "LBR",
      "currency_codes": "LRD"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "active": true,
      "createdAt": "2022-01-17T19:40:52.679491",
      "mobile_code": "218",
      "country_codes": "LBY",
      "currency_codes": "LYD"
    },
    {
      "name": "Liechtenstein",
      "active": true,
      "createdAt": "2022-01-17T19:41:52.275394",
      "mobile_code": "423",
      "country_codes": "LIE",
      "currency_codes": "CHF"
    },
    {
      "name": "Lithuania",
      "active": true,
      "createdAt": "2022-01-17T19:43:24.04162",
      "mobile_code": "370",
      "country_codes": "LTU",
      "currency_codes": "EUR"
    },
    {
      "name": "Luxembourg",
      "active": true,
      "createdAt": "2022-01-17T19:44:16.923423",
      "mobile_code": "352",
      "country_codes": "LUX",
      "currency_codes": "EUR"
    },
    {
      "name": "Macao",
      "active": true,
      "createdAt": "2022-01-17T19:45:18.979055",
      "mobile_code": "853",
      "country_codes": "MAC",
      "currency_codes": "MOP"
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "active": true,
      "createdAt": "2022-01-17T19:47:41.808041",
      "mobile_code": "389",
      "country_codes": "MKD",
      "currency_codes": "MKD"
    },
    {
      "name": "Madagascar",
      "active": true,
      "createdAt": "2022-01-17T19:48:47.397833",
      "mobile_code": "261",
      "country_codes": "MDG",
      "currency_codes": "MGA"
    },
    // {
    //   "name": "Malawi",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:51:00.665356",
    //   "mobile_code": "265",
    //   "country_codes": "MWI",
    //   "currency_codes": "MWK"
    // },
    {
      "name": "Malaysia",
      "active": true,
      "createdAt": "2022-01-17T19:52:06.53844",
      "mobile_code": "60",
      "country_codes": "MYS",
      "currency_codes": "MYR"
    },
    // {
    //   "name": "Maldives",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:53:01.629132",
    //   "mobile_code": "960",
    //   "country_codes": "MDV",
    //   "currency_codes": "MVR"
    // },
    {
      "name": "Mali",
      "active": true,
      "createdAt": "2022-01-17T19:53:59.443138",
      "mobile_code": "223",
      "country_codes": "MLI",
      "currency_codes": "XOF"
    },
    {
      "name": "Malta",
      "active": true,
      "createdAt": "2022-01-17T19:54:55.0427",
      "mobile_code": "356",
      "country_codes": "MLT",
      "currency_codes": "EUR"
    },
    {
      "name": "Marshall Islands",
      "active": true,
      "createdAt": "2022-01-17T19:55:45.718593",
      "mobile_code": "692",
      "country_codes": "MHL",
      "currency_codes": "USD"
    },
    {
      "name": "Martinique",
      "active": true,
      "createdAt": "2022-01-17T19:56:40.134305",
      "mobile_code": "596",
      "country_codes": "MTQ",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Mauritania",
    //   "active": true,
    //   "createdAt": "2022-01-17T19:57:47.788941",
    //   "mobile_code": "222",
    //   "country_codes": "MRT",
    //   "currency_codes": "MRU"
    // },
    {
      "name": "Mauritius",
      "active": true,
      "createdAt": "2022-01-17T19:58:43.661088",
      "mobile_code": "230",
      "country_codes": "MUS",
      "currency_codes": "MUR"
    },
    {
      "name": "India",
      "active": true,
      "createdAt": "2022-01-17T19:13:17.552272",
      "mobile_code": "91",
      "country_codes": "IND",
      "currency_codes": "INR"
    },
    {
      "name": "Mexico",
      "active": true,
      "createdAt": "2022-01-17T19:59:38.322823",
      "mobile_code": "52",
      "country_codes": "MEX",
      "currency_codes": "MXN"
    },
    {
      "name": "Micronesia, Federated States of",
      "active": true,
      "createdAt": "2022-01-17T20:00:43.788703",
      "mobile_code": "691",
      "country_codes": "FSM",
      "currency_codes": "USD"
    },
    {
      "name": "Moldova, Republic of",
      "active": true,
      "createdAt": "2022-01-17T20:01:46.651967",
      "mobile_code": "373",
      "country_codes": "MDA",
      "currency_codes": "MDL"
    },
    {
      "name": "Monaco",
      "active": true,
      "createdAt": "2022-01-17T20:02:37.429869",
      "mobile_code": "377",
      "country_codes": "MCO",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Mongolia",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:03:25.442982",
    //   "mobile_code": "976",
    //   "country_codes": "MNG",
    //   "currency_codes": "MNT"
    // },
    // {
    //   "name": "Montserrat",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:04:26.521779",
    //   "mobile_code": "1 664",
    //   "country_codes": "MSR",
    //   "currency_codes": "XCD"
    // },
    {
      "name": "Morocco",
      "active": true,
      "createdAt": "2022-01-17T20:05:13.775738",
      "mobile_code": "212",
      "country_codes": "MAR",
      "currency_codes": "MAD"
    },
    {
      "name": "Mozambiqu",
      "active": true,
      "createdAt": "2022-01-17T20:06:08.063276",
      "mobile_code": "258",
      "country_codes": "MOZ",
      "currency_codes": "MZN"
    },
    {
      "name": "Myanmar",
      "active": true,
      "createdAt": "2022-01-17T20:07:00.816388",
      "mobile_code": "95",
      "country_codes": "MMR",
      "currency_codes": "MMK"
    },
    {
      "name": "Namibia",
      "active": true,
      "createdAt": "2022-01-17T20:08:00.201894",
      "mobile_code": "264",
      "country_codes": "NAM",
      "currency_codes": "ZAR"
    },
    {
      "name": "Nauru",
      "active": true,
      "createdAt": "2022-01-17T20:09:29.025958",
      "mobile_code": "674",
      "country_codes": "NRU",
      "currency_codes": "AUD"
    },
    {
      "name": "Nepal",
      "active": true,
      "createdAt": "2022-01-17T20:10:14.11049",
      "mobile_code": "977",
      "country_codes": "NPL",
      "currency_codes": "NPR"
    },
    {
      "name": "Netherlands",
      "active": true,
      "createdAt": "2022-01-17T20:11:04.044124",
      "mobile_code": "31",
      "country_codes": "NLD",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Netherlands Antilles",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:13:54.225892",
    //   "mobile_code": "599",
    //   "country_codes": "ANT",
    //   "currency_codes": "ANG"
    // },
    // {
    //   "name": "New Caledonia",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:14:54.087271",
    //   "mobile_code": "687",
    //   "country_codes": "NCL",
    //   "currency_codes": "XPF"
    // },
    {
      "name": "New Zealand",
      "active": true,
      "createdAt": "2022-01-17T20:16:05.948985",
      "mobile_code": "64",
      "country_codes": "NZL",
      "currency_codes": "NZD"
    },
    {
      "name": "Nicaragua",
      "active": true,
      "createdAt": "2022-01-17T20:17:22.45377",
      "mobile_code": "505",
      "country_codes": "NIC",
      "currency_codes": "NIO"
    },
    {
      "name": "Niger",
      "active": true,
      "createdAt": "2022-01-17T20:18:25.860143",
      "mobile_code": "227",
      "country_codes": "NER",
      "currency_codes": "XOF"
    },
    {
      "name": "Nigeria",
      "active": true,
      "createdAt": "2022-01-17T20:19:21.411095",
      "mobile_code": "234",
      "country_codes": "NGA",
      "currency_codes": "NGN"
    },
    {
      "name": "Niue",
      "active": true,
      "createdAt": "2022-01-17T20:20:09.406376",
      "mobile_code": "683",
      "country_codes": "NIU",
      "currency_codes": "NZD"
    },
    {
      "name": "Norfolk Island",
      "active": true,
      "createdAt": "2022-01-17T20:21:14.080862",
      "mobile_code": "6723",
      "country_codes": "NFK",
      "currency_codes": "AUD"
    },
    {
      "name": "Northern Mariana Islands",
      "active": true,
      "createdAt": "2022-01-17T20:22:18.838405",
      "mobile_code": "1 670",
      "country_codes": "MNP",
      "currency_codes": "USD"
    },
    {
      "name": "Norway",
      "active": true,
      "createdAt": "2022-01-17T20:23:51.702608",
      "mobile_code": "47",
      "country_codes": "NOR",
      "currency_codes": "NOK"
    },
    {
      "name": "Oman",
      "active": true,
      "createdAt": "2022-01-17T20:24:40.002733",
      "mobile_code": "968",
      "country_codes": "OMN",
      "currency_codes": "OMR"
    },
    {
      "name": "Palau",
      "active": true,
      "createdAt": "2022-01-17T20:26:34.698452",
      "mobile_code": "680",
      "country_codes": "PLW",
      "currency_codes": "USD"
    },
    // {
    //   "name": "Palestinian Territory, Occupied",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:28:37.449262",
    //   "mobile_code": "970",
    //   "country_codes": "PSE",
    //   "currency_codes": "-"
    // },
    {
      "name": "Panama",
      "active": true,
      "createdAt": "2022-01-17T20:29:49.448904",
      "mobile_code": "507",
      "country_codes": "PAN",
      "currency_codes": "USD"
    },
    // {
    //   "name": "Papua New Guinea",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:30:49.607065",
    //   "mobile_code": "675",
    //   "country_codes": "PNG",
    //   "currency_codes": "PGK"
    // },
    {
      "name": "Paraguay",
      "active": true,
      "createdAt": "2022-01-17T20:31:36.932563",
      "mobile_code": "595",
      "country_codes": "PRY",
      "currency_codes": "PYG"
    },
    {
      "name": "Peru",
      "active": true,
      "createdAt": "2022-01-17T20:33:18.577116",
      "mobile_code": "51",
      "country_codes": "PER",
      "currency_codes": "PEN"
    },
    {
      "name": "Philippines",
      "active": true,
      "createdAt": "2022-01-17T20:34:11.155423",
      "mobile_code": "63",
      "country_codes": "PHL",
      "currency_codes": "PHP"
    },
    {
      "name": "Pitcairn",
      "active": true,
      "createdAt": "2022-01-17T20:35:10.958132",
      "mobile_code": "64",
      "country_codes": "PCN",
      "currency_codes": "NZD"
    },
    {
      "name": "Poland",
      "active": true,
      "createdAt": "2022-01-17T20:36:11.997344",
      "mobile_code": "48",
      "country_codes": "POL",
      "currency_codes": "PLN"
    },
    {
      "name": "Portugal",
      "active": true,
      "createdAt": "2022-01-17T20:36:59.346672",
      "mobile_code": "351",
      "country_codes": "PRT",
      "currency_codes": "EUR"
    },
    {
      "name": "Puerto Rico",
      "active": true,
      "createdAt": "2022-01-17T20:37:57.74165",
      "mobile_code": "1 787",
      "country_codes": "PRI",
      "currency_codes": "USD"
    },
    {
      "name": "Reunion",
      "active": true,
      "createdAt": "2022-01-17T20:39:00.637564",
      "mobile_code": "262",
      "country_codes": "REU",
      "currency_codes": "EUR"
    },
    {
      "name": "Romania",
      "active": true,
      "createdAt": "2022-01-17T20:39:46.873009",
      "mobile_code": "40",
      "country_codes": "ROU",
      "currency_codes": "RON"
    },
    {
      "name": "Russian Federation",
      "active": true,
      "createdAt": "2022-01-17T20:40:37.559508",
      "mobile_code": "7",
      "country_codes": "RUS",
      "currency_codes": "RUB"
    },
    {
      "name": "RWANDA",
      "active": true,
      "createdAt": "2022-01-17T20:41:27.86294",
      "mobile_code": "250",
      "country_codes": "RWA",
      "currency_codes": "RWF"
    },
    // {
    //   "name": "Saint Helena",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:42:43.781139",
    //   "mobile_code": "290",
    //   "country_codes": "SHN",
    //   "currency_codes": "SHP"
    // },
    // {
    //   "name": "Saint Kitts and Nevis",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:43:56.045162",
    //   "mobile_code": "1 869",
    //   "country_codes": "KNA",
    //   "currency_codes": "XCD"
    // },
    // {
    //   "name": "Saint Lucia",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:45:07.579805",
    //   "mobile_code": "1 758",
    //   "country_codes": "LCA",
    //   "currency_codes": "XCD"
    // },
    {
      "name": "Saint Pierre and Miquelon",
      "active": true,
      "createdAt": "2022-01-17T20:46:12.041868",
      "mobile_code": "508",
      "country_codes": "SPM",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Saint Vincent and the Grenadines",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:47:06.000021",
    //   "mobile_code": "1 784",
    //   "country_codes": "VCT",
    //   "currency_codes": "XCD"
    // },
    // {
    //   "name": "Samoa",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:47:54.173741",
    //   "mobile_code": "685",
    //   "country_codes": "WSM",
    //   "currency_codes": "WST"
    // },
    {
      "name": "San Marino",
      "active": true,
      "createdAt": "2022-01-17T20:48:51.862078",
      "mobile_code": "378",
      "country_codes": "SMR",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Sao Tome and Principe",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:49:56.304162",
    //   "mobile_code": "239",
    //   "country_codes": "STP",
    //   "currency_codes": "STN"
    // },
    {
      "name": "Senegal",
      "active": true,
      "createdAt": "2022-01-17T20:52:02.260083",
      "mobile_code": "221",
      "country_codes": "SEN",
      "currency_codes": "XOF"
    },
    {
      "name": "Serbia and Montenegro",
      "active": true,
      "createdAt": "2022-01-17T20:53:18.61659",
      "mobile_code": "381",
      "country_codes": "SRB",
      "currency_codes": "RSD"
    },
    // {
    //   "name": "Seychelles",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:54:12.573114",
    //   "mobile_code": "248",
    //   "country_codes": "SYC",
    //   "currency_codes": "SCR"
    // },
    // {
    //   "name": "Sierra Leone",
    //   "active": true,
    //   "createdAt": "2022-01-17T20:55:14.005273",
    //   "mobile_code": "232",
    //   "country_codes": "SLE",
    //   "currency_codes": "SLL"
    // },
    {
      "name": "Singapore",
      "active": true,
      "createdAt": "2022-01-17T21:12:28.08583",
      "mobile_code": "65",
      "country_codes": "SGP",
      "currency_codes": "SGD"
    },
    {
      "name": "Slovakia",
      "active": true,
      "createdAt": "2022-01-17T21:13:46.753308",
      "mobile_code": "421",
      "country_codes": "SVK",
      "currency_codes": "EUR"
    },
    {
      "name": "Slovenia",
      "active": true,
      "createdAt": "2022-01-17T21:14:42.081345",
      "mobile_code": "386",
      "country_codes": "SVN",
      "currency_codes": "EUR"
    },
    // {
    //   "name": "Solomon Islands",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:15:42.181633",
    //   "mobile_code": "677",
    //   "country_codes": "SLB",
    //   "currency_codes": "SBD"
    // },
    {
      "name": "Somalia",
      "active": true,
      "createdAt": "2022-01-17T21:17:06.874563",
      "mobile_code": "252",
      "country_codes": "SOM",
      "currency_codes": "SOSO"
    },
    {
      "name": "South Africa",
      "active": true,
      "createdAt": "2022-01-17T21:18:14.700544",
      "mobile_code": "27",
      "country_codes": "ZAF",
      "currency_codes": "ZAR"
    },
    // {
    //   "name": "South Georgia and the South Sandwich Islands",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:19:16.10224",
    //   "mobile_code": "500",
    //   "country_codes": "SGS",
    //   "currency_codes": "-"
    // },
    {
      "name": "Spain",
      "active": true,
      "createdAt": "2022-01-17T21:20:20.912207",
      "mobile_code": "34",
      "country_codes": "ESP",
      "currency_codes": "EUR"
    },
    {
      "name": "Sri Lanka",
      "active": true,
      "createdAt": "2022-01-17T21:21:27.37962",
      "mobile_code": "94",
      "country_codes": "LKA",
      "currency_codes": "LKR"
    },
    {
      "name": "Sudan",
      "active": true,
      "createdAt": "2022-01-17T21:23:00.300832",
      "mobile_code": "249",
      "country_codes": "SDN",
      "currency_codes": "SDG"
    },
    // {
    //   "name": "SuriName",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:24:24.69688",
    //   "mobile_code": "597",
    //   "country_codes": "SUR",
    //   "currency_codes": "SRD"
    // },
    {
      "name": "Svalbard and Jan Mayen",
      "active": true,
      "createdAt": "2022-01-17T21:25:33.812306",
      "mobile_code": "47",
      "country_codes": "SJM",
      "currency_codes": "NOK"
    },
    // {
    //   "name": "Swaziland",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:27:51.284646",
    //   "mobile_code": "268",
    //   "country_codes": "SWZ",
    //   "currency_codes": "SZL"
    // },
    {
      "name": "Sweden",
      "active": true,
      "createdAt": "2022-01-17T21:29:13.662218",
      "mobile_code": "46",
      "country_codes": "SWE",
      "currency_codes": "SEK"
    },
    {
      "name": "Switzerland",
      "active": true,
      "createdAt": "2022-01-17T21:30:16.217223",
      "mobile_code": "41",
      "country_codes": "CHE",
      "currency_codes": "CHF"
    },
    {
      "name": "Syrian Arab Republic",
      "active": true,
      "createdAt": "2022-01-17T21:32:56.578338",
      "mobile_code": "963",
      "country_codes": "SYR",
      "currency_codes": "SYP"
    },
    {
      "name": "Taiwan, Province of China",
      "active": true,
      "createdAt": "2022-01-17T21:34:18.141836",
      "mobile_code": "886",
      "country_codes": "TWN",
      "currency_codes": "TWD"
    },
    // {
    //   "name": "Tajikistan",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:35:35.452258",
    //   "mobile_code": "992",
    //   "country_codes": "TJK",
    //   "currency_codes": "TJS"
    // },
    {
      "name": "Tanzania, United Republic of",
      "active": true,
      "createdAt": "2022-01-17T21:36:46.922281",
      "mobile_code": "255",
      "country_codes": "TZA",
      "currency_codes": "TZS"
    },
    {
      "name": "Thailand",
      "active": true,
      "createdAt": "2022-01-17T21:38:13.188325",
      "mobile_code": "66",
      "country_codes": "THA",
      "currency_codes": "THB"
    },
    {
      "name": "Timor-Leste",
      "active": true,
      "createdAt": "2022-01-17T21:39:27.57732",
      "mobile_code": "670",
      "country_codes": "TLS",
      "currency_codes": "USD"
    },
    {
      "name": "Togo",
      "active": true,
      "createdAt": "2022-01-17T21:40:25.170917",
      "mobile_code": "228",
      "country_codes": "TGO",
      "currency_codes": "XOF"
    },
    {
      "name": "Tokelau",
      "active": true,
      "createdAt": "2022-01-17T21:41:41.760176",
      "mobile_code": "690",
      "country_codes": "TKL",
      "currency_codes": "NZD"
    },
    {
      "name": "Tonga",
      "active": true,
      "createdAt": "2022-01-17T21:42:32.10945",
      "mobile_code": "676",
      "country_codes": "TON",
      "currency_codes": "TOP"
    },
    {
      "name": "Trinidad and Tobago",
      "active": true,
      "createdAt": "2022-01-17T21:43:50.091403",
      "mobile_code": "1 868",
      "country_codes": "TTO",
      "currency_codes": "TTD"
    },
    {
      "name": "Tunisia",
      "active": true,
      "createdAt": "2022-01-17T21:44:56.008301",
      "mobile_code": "216",
      "country_codes": "TUN",
      "currency_codes": "TND"
    },
    // {
    //   "name": "Turkmenistan",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:47:23.231188",
    //   "mobile_code": "993",
    //   "country_codes": "TKM",
    //   "currency_codes": "TMT"
    // },
    {
      "name": "Turks and Caicos Islands",
      "active": true,
      "createdAt": "2022-01-17T21:48:28.547574",
      "mobile_code": "1 649",
      "country_codes": "TCA",
      "currency_codes": "USD"
    },
    {
      "name": "Tuvalu",
      "active": true,
      "createdAt": "2022-01-17T21:49:58.163322",
      "mobile_code": "688",
      "country_codes": "TUV",
      "currency_codes": "AUD"
    },
    {
      "name": "Uganda",
      "active": true,
      "createdAt": "2022-01-17T21:50:41.046313",
      "mobile_code": "256",
      "country_codes": "UGA",
      "currency_codes": "UGX"
    },
    {
      "name": "Ukraine",
      "active": true,
      "createdAt": "2022-01-17T21:51:48.325307",
      "mobile_code": "380",
      "country_codes": "UKR",
      "currency_codes": "UAH"
    },
    {
      "name": "United Kingdom",
      "active": true,
      "createdAt": "2022-01-17T21:53:38.959886",
      "mobile_code": "44",
      "country_codes": "GBR",
      "currency_codes": "GBP"
    },
    {
      "name": "United States",
      "active": true,
      "createdAt": "2022-01-17T21:54:31.317384",
      "mobile_code": "1",
      "country_codes": "USA",
      "currency_codes": "USD"
    },
    {
      "name": "United States Minor Outlying Islands",
      "active": true,
      "createdAt": "2022-01-17T21:55:20.81812",
      "mobile_code": "1 808",
      "country_codes": "UMI",
      "currency_codes": "USD"
    },
    // {
    //   "name": "Uruguay",
    //   "active": true,
    //   "createdAt": "2022-01-17T21:56:13.930032",
    //   "mobile_code": "598",
    //   "country_codes": "URY",
    //   "currency_codes": "UYI"
    // },
    {
      "name": "Uzbekistan",
      "active": true,
      "createdAt": "2022-01-17T21:57:21.909028",
      "mobile_code": "998",
      "country_codes": "UZB",
      "currency_codes": "UZS"
    },
    {
      "name": "Vanuatu",
      "active": true,
      "createdAt": "2022-01-17T21:58:33.438341",
      "mobile_code": "678",
      "country_codes": "VUT",
      "currency_codes": "VUV"
    },
    {
      "name": "Venezuela",
      "active": true,
      "createdAt": "2022-01-17T21:59:33.817306",
      "mobile_code": "58",
      "country_codes": "VEN",
      "currency_codes": "VEF"
    },
    {
      "name": "Viet Nam",
      "active": true,
      "createdAt": "2022-01-17T22:00:21.709764",
      "mobile_code": "84",
      "country_codes": "VNM",
      "currency_codes": "VND"
    },
    {
      "name": "Virgin Islands, British",
      "active": true,
      "createdAt": "2022-01-17T22:01:33.740974",
      "mobile_code": "1 284",
      "country_codes": "VGB",
      "currency_codes": "USD"
    },
    {
      "name": "Virgin Islands, U.S.",
      "active": true,
      "createdAt": "2022-01-17T22:02:31.377771",
      "mobile_code": "1 340",
      "country_codes": "VIR",
      "currency_codes": "USD"
    },
    // {
    //   "name": "Wallis and Futuna",
    //   "active": true,
    //   "createdAt": "2022-01-17T22:03:24.136178",
    //   "mobile_code": "681",
    //   "country_codes": "WLF",
    //   "currency_codes": "XPF"
    // },
    {
      "name": "Western Sahara",
      "active": true,
      "createdAt": "2022-01-17T22:04:17.855598",
      "mobile_code": "212 28",
      "country_codes": "ESH",
      "currency_codes": "MAD"
    },
    {
      "name": "Yemen",
      "active": true,
      "createdAt": "2022-01-17T22:05:01.607038",
      "mobile_code": "967",
      "country_codes": "YEM",
      "currency_codes": "YER"
    },
    // {
    //   "name": "Zambia",
    //   "active": true,
    //   "createdAt": "2022-01-17T22:05:52.980891",
    //   "mobile_code": "260",
    //   "country_codes": "ZMB",
    //   "currency_codes": "ZMW"
    // },
    {
      "name": "Zimbabwe",
      "active": true,
      "createdAt": "2022-01-17T22:06:44.368856",
      "mobile_code": "263",
      "country_codes": "ZWE",
      "currency_codes": "ZWL"
    },
    {
      "name": "Turkey",
      "active": true,
      "createdAt": "2022-01-17T22:09:11.889402",
      "mobile_code": "90",
      "country_codes": "TUR",
      "currency_codes": "TRY"
    },
    {
      "name": "United Arab Emirates",
      "active": true,
      "createdAt": "2022-01-17T21:52:39.403816",
      "mobile_code": "971",
      "country_codes": "ARE",
      "currency_codes": "AED"
    },
    // {
    //   "name": "Gambia",
    //   "active": true,
    //   "createdAt": "2022-01-17T18:46:39.653527",
    //   "mobile_code": "220",
    //   "country_codes": "GMB",
    //   "currency_codes": "GMD"
    // },
    {
      "name": "Pakistan",
      "active": true,
      "createdAt": "2022-01-17T20:25:33.117133",
      "mobile_code": "92",
      "country_codes": "PAK",
      "currency_codes": "PKR"
    },
    {
      "name": "Saudi Arabia",
      "active": true,
      "createdAt": "2022-01-17T20:50:54.797712",
      "mobile_code": "966",
      "country_codes": "SAU",
      "currency_codes": "SAR"
    }
  ]