import { KrtiPayService } from './api'






const KRTI_WEB_API = process.env.REACT_APP_KRTI_WEB_API;
const KRTI_MOBILE_API = process.env.REACT_APP_KRTI_MOBILE_API;
const merchant_secret = process.env.REACT_APP_MERCHANT_SECRET;
const brand_id = process.env.REACT_APP_BRAND_ID;
const client_id = process.env.REACT_APP_CLIENT_ID;



const header = {
    'merchant_secret': merchant_secret,
    'client_id': client_id
}





export const authMerchant = async (req) => {
    try {

        const authHead = { ...header, ...req }

        return await KrtiPayService.post(`${KRTI_WEB_API}/merchant-payments/validateClient/${brand_id}`, {}, { headers: authHead })
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


export const registerCustomer = async (req) => {
    try {
        let body = {
            ...req,
            "otpSignature": "s",
            "session_id": "43eb3f62-270e-4f93-9b85-5e6c0d4c824a"
        }


        return await KrtiPayService.post(`${KRTI_MOBILE_API}/user/registerUser`, body, { headers: header })
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const verifyOtp = async (req) => {
    try {
        let body = {
            ...req,
            "verifyType": "Login or Register"
        }


        return await KrtiPayService.post(`${KRTI_MOBILE_API}/user/verifyOtp`, body, { headers: header })
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const payNow = async (req) => {
    try {
        let body = {
            ...req,
            "verifyType": "Login or Register"
        }


        return await KrtiPayService.post(`${KRTI_WEB_API}/payment/generate-token`, body, { headers: header })
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}

export const openKrtiPayment = async (req) => {
    try {
        const payload = {
            ...req,
            brand_id
        }
        return await KrtiPayService.post(`${KRTI_WEB_API}/payment/open-krtipay`, payload, { headers: header })
    } catch (error) {
        console.log(error)
        return error?.response?.data
    }
}


































