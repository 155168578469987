import React from "react";
import AdminNav from "./AdminNav";
import AdminHeader from "./AdminHeader";

const AdminUsers = () => {
  return (
    <div>
      <AdminHeader />

      <AdminNav />
      <h1>Admin Users!</h1>
    </div>
  );
};

export default AdminUsers;
